import "./App.css";
// import ComingSoon from "./components/ComingSoon";
import Logo from "./components/Logo";
import Heroes from "./components/Heroes";
import CountrySelector from "./components/CountrySelector";

function App() {
  return (
    <div className="bg">
      <div className="App">
        {/* <ComingSoon /> */}
        <Logo />
        <CountrySelector />
        <Heroes />
      </div>
    </div>
  );
}

export default App;
