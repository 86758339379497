import React from 'react'

const Heroes = () => {
  return (
    <div className='heroesFooter'>
        <img src="/heroes.png" alt="Fast Heroes" />
    </div>
  );
};

export default Heroes