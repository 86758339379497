import React from 'react'

const Logo = () => {
  return (
    <div className='fastLogo'>
        <img src="/fastLogo.png" alt="Fast Heroes Logo" />
    </div>
  );
};

export default Logo