import React, { useState } from "react";

const countries = [
  "Argentina",
  "Brazil",
  "Bulgaria",
  "Canada",
  "Czech Republic",
  "Greece",
  "Hungary",
  "Iceland",
  "Italy",
  "Kazakhstan",
  "Kyrgyzstan",
  "Latvia",
  "Malaysia",
  "Poland",
  "Portugal",
  "Romania",
  "Singapore",
  "Slovakia",
  "South Africa",
  "Spain",
  "Ukraine",
];

const contacts = {
  Argentina: [
    { name: "Andrés Kusminsky", email: "andres@atrainlab.com" },
    { name: "Vicky Carrano", email: "victoria@atrainlab.com" }
  ],
  Brazil: [
    {
      name: "Kamila Fachola", email: "kamila.fachola@boehringer-ingelheim.com",
    },
  ],
  Bulgaria: [
    { name: "Dimitar Hadzhivalchev", email: "hadzhivalchev.dimitar@gmail.com" },
    { name: "Elica Hadzhivalcheva", email: "elica@fastheroes112.com" },
  ],
  Canada: [
    { name: "Keli Cristofaro", email: "keli.cristofaro@tbh.net" },
  ],
  "Czech Republic": [
    { name: "Lucie Pražáková", email: "lucie.prazakova@fnusa.cz" },
    { name: "Zuzana Bedrichova", email: "bedrichova@hm-institute.org" },
  ],
  Greece: [
    { name: "Leda Papadopoulou", email: "lpapadopoulou@epidirect.gr" },
    { name: "Dimitra Kapetaniou", email: "dkapetaniou@epidirect.gr " },
    { name: "Prof. Harriet Proios", email: "hproios@gmail.com" },
    { name: "Maria Baskini", email: "mariabaskini@gmail.com" },
    { name: "Myrto Pyrrou", email: "mypyrrou@gmail.com " },
  ],
  Iceland: [
    { name: "Bryndís Nielsen", email: "bryndis@athygli.is" },
    { name: "Birna Drofn Jonasdottir", email: "birna@athygli.is" },
  ],
  Hungary: [
    { name: "Andrea Van den Aker", email: "andrea.kcskmt@gmail.com" },
    { name: "Peter Z. Wellisch", email: "peter.z.wellisch@gmail.com" },
  ],
  Italy: [
    { name: "Lidia Romagnuolo", email: "l.romagnuolo@aimcommunication.eu" },
    { name: "Nicole De Angelis", email: "n.deangelis@aimcommunication.eu" },
  ],
  Kazakhstan: [
    { name: "Zhansaya Khamit", email: "zh.khamit@hls.kz" },
    { name: "Zhibek Mukusheva", email: "zh.mukusheva@hls.kz" },
  ],
  Kyrgyzstan: [
    { name: "Dr Asel Kerimkulova", email: "kerimkulovaasel683@gmail.com" },
    { name: "Guzel Turdubaeva", email: "guzel.turdubaeva@mail.ru" },
  ],
  Latvia: [
    { name: "Mārtiņš Zvackis", email: "martins.zvackis@spkc.gov.lv" },
    { name: "Anna Sperga", email: "anna.sperga@spkc.gov.lv" },
  ],
  Malaysia: [
    { name: "Stephy Chan", email: "stephy.chan@boehringer-ingelheim.com" },
    { name: "Dr Radha Swathe Priya", email: "radha.malon@boehringer-ingelheim.com" },
  ],
  Poland: [
    { name: "Dariusz Zieliński", email: "d.zielinski@primum.pl" },
    { name: "Gabriela Szkopek", email: "g.szkopek@primum.pl" },
    { name: "Dorota Niemczynowicz", email: "d.niemczynowicz@primum.pl" },
    { name: "Paulina Durakiewicz", email: "p.durakiewicz@primum.pl" },
  ],
  Portugal: [
    { name: "Mariana Esteves", email: "mariana.esteves@hillandknowlton.com" },
    { name: "Sara Tomé", email: "sara.tome@hillandknowlton.com" },
  ],
  Romania: [
    { name: "Bogdan Alecu", email: "bogdan@thesocialnest.ro" },
    { name: "Vlad Mihailescu", email: "vlad@thesocialnest.ro" },
  ],
  Singapore: [
    { name: "Emileen Chieng", email: "emileen.chieng@boehringer-ingelheim.com" },
    { name: "Wu Tze Shen", email: "james@preschoolmarket.com" },
    { name: "Shamala Thilarajah", email: "shamala.thilarajah@sgh.com.sg" },
  ],
  Slovakia: [
    { name: "Lubica Fidesová", email: "lubica.fidesova@grapepr.sk" },
  ],
  "South Africa": [
    { name: "Renathe van der Merwe", email: "renathe.vandermerwe@iqvia.com" },
  ],
  Spain: [
    { name: "Pancho López Garbayo", email: "franciscolopez@airun.cat" },
    { name: "Roger Piera", email: "rogerpiera@airun.cat" },
  ],
  Ukraine: [
    { name: "Anastasiia Klysakova", email: "aklysakova@onehealth.com.ua" },
    { name: "Natali Olbert", email: "nolbert@onehealth.com.ua" },
  ],
};


const CountrySelector = () => {
    const [selectedCountry, setSelectedCountry] = useState("");
    
    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

  return (
    <div className="countrySelector">
      <select value={selectedCountry} onChange={handleCountryChange}>
        <option value="">Select your country</option>
        {countries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </select>
      <div>
        {selectedCountry && (
          <>
            <div className="contactsReturned">
              <h3>Contacts in {selectedCountry}</h3>
              {contacts[selectedCountry] ? (
                contacts[selectedCountry].map((contact) => (
                  <div className="contactDetails" key={contact.email}>
                    <strong>{contact.name}</strong>: <br />
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </div>
                ))
              ) : (
                <p>No contacts available for this country</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CountrySelector;
